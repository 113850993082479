import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import { BsFillCheckCircleFill } from "react-icons/bs";

const KYPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "About-Us-hero-image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img1: wpMediaItem(title: { eq: "Karen" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Karen Young",
				item: {
					url: `${siteUrl}/about-us/karen-young`,
					id: `${siteUrl}/about-us/karen-young`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Karen Young | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/karen-young`,
					title: "Karen Young | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > About us > Karen Young"
						title="Karen Young"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="position-relative py-5 pt-md-0 pb-lg-7">
					<Container>
						<Row>
							<Col
								className="position-absolute d-none d-xl-block "
								style={{ top: "-12rem" }}
								lg={3}
							>
								<div>
									<GatsbyImage
										style={{
											maxWidth: "100%",
										}}
										image={data.img1.localFile.childImageSharp.gatsbyImageData}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
						</Row>
						<Row className="  ps-xl-7 justify-content-xl-end">
							<Col className=" d-xl-none pb-5 pb-lg-0 " lg={4}>
								<div>
									<GatsbyImage
										style={{
											maxWidth: "100%",
										}}
										image={data.img1.localFile.childImageSharp.gatsbyImageData}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
							<Col lg={7} xl={8}>
								<p>
									Karen qualified from the University of East London in 1996.
									She began her therapy career working in London at The Homerton
									Hospital, St Mary’s Hospital and The Royal London Hospital
									where she specialised as a neurological physiotherapist, she
									then continued her career leading the neurological therapy
									team at East Surrey Hospital. Karen has over 15 years’
									experience in managing patients with neurological conditions
									in the acute assessment, surgical, rehabilitation,
									out-patient, and community settings.
								</p>
								<p>
									{" "}
									She joined the facial therapy team at Queen Victoria NHS
									Foundation Trust in 2017 and has worked alongside the
									multi-disciplinary team (Plastic Surgeons, Oculoplastic
									Surgeons, Psychotherapists), to provide expert care and
									attention for patients recovering from facial paralysis.
								</p>
								<p>
									{" "}
									She is vice chair of Facial Therapy Specialists International
									and has presented to therapists about facial rehabilitation
									both in the UK and Internationally. Karen’s treatment
									approaches will include a thorough assessment of muscle
									function and facial movement, individually tailored home
									management programmes, evidence based treatment strategies
									including, neuromuscular re-education, relaxation, soft tissue
									mobilisation and biofeedback, as appropriate.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section className=" pb-5 pb-lg-7 py-xl-7">
					<Container>
						<Row>
							<Col className="text-center">
								<h2 className="text-dark-bg pb-4 ">Experience</h2>
							</Col>
						</Row>
						<Row className="pt-5">
							<Col className="pb-5 pb-lg-0" lg={6}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Advanced Facial Therapist – specialising in Facial Therapy and
									Facial Injecting (BTX-A)
								</p>
							</Col>
							<Col lg={6}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Independent Prescriber and Botulinum Toxin (BTX-A)
									practitioner for the management of synkinesis
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="mb-5 mb-lg-0" lg={6}>
								<h2 className="text-dark-bg pb-4 ">Qualifications</h2>
								<p>
									· BSc (Hons) 2.1 in Physiotherapy and a Distinction in
									Clinical Practice
								</p>
								<p>· Non-Medical Prescribing – University of Surrey 2021</p>
								<p> · Non-Medical Prescribing, University of Brighton, 2016</p>
								<p>
									·12-month competency-based training programme Botulinum Toxin
									Type A, Queen Victoria Hospital NHS Foundation Trust, 2020
								</p>
							</Col>
							<Col lg={6}>
								<h2 className="text-dark-bg pb-4 ">Professional Memberships</h2>
								<p>
									. Health Care Professions Council -PH.51344 – including
									independent prescribing and supplementary prescribing
								</p>
								<p> · Chartered Society of Physiotherapy - 56272</p>
								<p> · Facial Therapy Specialists International – Vice Chair</p>
								<p>
									· ACPIN Association of Physiotherapists Interested in
									Neurology
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="text-dark-bg pb-5 text-center ">
									Credentials & Employment History
								</h2>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
							</Col>
						</Row>
					</Container>
				</section> */}
			</Layout>
		</>
	);
};

export default KYPage;
